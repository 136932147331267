<template>
    <!-- 头部 -->
  <div class="common_center">
    <div class="banner"></div>
    <div class="icon_show">
      <el-breadcrumb style="font-size: 16px;" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/software' }">软件开发</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/software/blockchain' }">区块链</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="mobile_title" ref="mobileTitle">
        <p class="mobile_title_one">区块链服务</p>
      </div>
      <div class="mobile_info_page" ref="mobileBox">
        <div class="mobile_info" v-for="items in iconList" :key="items.id">
          <p class="mobile_info_one">{{items.title}}</p>
          <p class="mobile_info_tow">{{items.info}}</p>
        </div>
      </div>
      <div class="mobile_title" ref="mobileTitle1">
        <p class="mobile_title_one">区块链应用场景</p>
      </div>
      <div class="last_box" ref="mobileBox1">
        <div class="last_box_single" v-for="items in lastList" :key="items.id">
          <img :src="items.src">
          <p class="last_box_title">{{items.title}}</p>
          <p class="last_box_info">{{items.info}}</p>
        </div>
      </div>
    </div>
  </div>
    <!-- 路由出口 -->
</template>
<script>
export default {
  name: 'blockchain',
  data () {
    return {
      // icon图标
      iconList: [
        {
          title: '助力政务系统完善',
          info: '对于政务系统而言，区块链技术，可为跨级别、跨部门的数据互联互通提供可信环境，提升政务服务效率。区块链基础服务平台，助力传统软件公司布局“区块链+政务”场景应用。',
          id: 1
        },
        {
          title: '提升现有区块链改造效率',
          info: '区块链真正的应用价值，开始被许多传统企业所重视。很多传统软件公司也开始研发区块链产品，但区块链技术研究非一日之功能成，助力其快速实现区块链产品开发。',
          id: 2
        },
        {
          title: '推动应标区块链项目落地',
          info: '产业区块链已经是大势所趋，基于区块链技术的标的项目也不断涌现。应标企业在专业领域与系统集成方面十分专业，实现与传统应用的完美结合。',
          id: 3
        },
        {
          title: '助力区块链产品开发',
          info: '区块链作为一种具备去中心化、安全性高、信用成本低、无法篡改和资料公开透明等优势的技术，基于区块链技术研发的产品也越来越多。区块链底层开发费时费力，选用通用区块链平台则可以助力区块链产品快速成型。',
          id: 4
        }
      ],
      lastList: [
        {
          title: '供应链金融',
          info: 'SUPPLY CHAIN FINANCE',
          src: require('@/assets/img/softWare/blockchain/blockchain_icon1.png')
        },
        {
          title: '新能源',
          info: 'NEW ENERGY',
          src: require('@/assets/img/softWare/blockchain/blockchain_icon2.png')
        },
        {
          title: '政务',
          info: 'GOVERNMENT AFFAIRS',
          src: require('@/assets/img/softWare/blockchain/blockchain_icon3.png')
        },
        {
          title: '食品安全监管',
          info: 'FOOD SAFETY SUPERVISION',
          src: require('@/assets/img/softWare/blockchain/blockchain_icon4.png')
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const __currentScroll = window.pageYOffset
      // 此处数值越大，那么触发事件就越早
      if (__currentScroll >= this.$refs.mobileTitle.offsetTop - 600) {
        const DomArr = this.$refs.mobileTitle.className.split(' ')
        if (!DomArr.some(item => item === 'animated')) {
          if (this.$refs.mobileTitle.className === 'mobile_title') {
            this.$refs.mobileTitle.className = this.$refs.mobileTitle.className + ' animated bounceInUp'
            this.$refs.mobileBox.className = this.$refs.mobileBox.className + ' animated zoomInDown'
            this.$refs.mobileTitle1.className = this.$refs.mobileTitle1.className + ' animated bounceInUp'
          }
        }
      }
      if (__currentScroll >= this.$refs.mobileBox1.offsetTop - 750) {
        const DomArr = this.$refs.mobileBox1.className.split(' ')
        if (!DomArr.some(item => item === 'animated')) {
          if (this.$refs.mobileBox1.className === 'last_box') {
            this.$refs.mobileBox1.className = this.$refs.mobileBox1.className + ' animated zoomInDown'
          }
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.common_center{
  overflow: hidden;
  .banner {
    height: 500px;
    background: url('~@/assets/img/softWare/blockchain/inner_banner6.jpg') no-repeat center;
    background-size: 100% 100%;
  }
  .icon_show {
    width: 1400px;
    padding-top: 39px;
    margin: auto;
    overflow: hidden;
    .mobile_title{
      margin-top: 60px;
      margin-bottom: 60px;
      width: 100%;
      text-align: center;
      font-family: MicrosoftYaHei;
      color: #333333;
      .mobile_title_one {
        font-size: 36px;
      }
    }
    .mobile_info_page {
      display: flex;
      height: 345px;
      .mobile_info{
        border-radius: 10px;
        position: relative;
        background: #fafafa;
        width: 265px;
        height: 290px;
        margin-right: 20px;
        font-family: MicrosoftYaHei;
        color: #333333;
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        padding: 45px 35px 0 35px;
        .mobile_info_one {
          font-size: 24px;
          margin-bottom: 30px;
        }
        .mobile_info_tow {
          width: 223px;
          font-size: 14px;
          color: #666666;
        }
      }
      .mobile_info:hover {
          background: #fff;
          background: url('~@/assets/img/softWare/blockchain/blockchain_bg.png') no-repeat 100% 100%;
          box-shadow: 4px 4px 12px 0px rgba(219, 219, 219, 0.57);
          .mobile_info_one{
            color: #0773fc;
          }
        }
      .mobile_info:last-child {
        margin-right: 0;
      }
    }
    .last_box{
      width: 100%;
      height: 335px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-between;
      .last_box_single {
        width: 335px;
        height: 325px;
        border: solid 1px #f0f0f0;
        font-family: MicrosoftYaHei;
        img {
          margin-top: 50px;
          margin-bottom: 30px;
        }
        .last_box_title{
          font-size: 24px;
          letter-spacing: 2px;
          color: #333333;
          margin-bottom: 20px;
        }
        .last_box_info{
          font-size: 18px;
          letter-spacing: 2px;
          color: #808080;
        }
      }
      .last_box_single:hover {
        border: none;
        box-shadow: 4px 4px 12px 0px rgba(219, 219, 219, 0.57);
      }
    }
  }
}
</style>
